import React from 'react';
import { Box, Typography, Container, Avatar } from '@mui/material';
import './css/About.css';
import AnanyaImage from '../assets/ananya.jpeg';
import DwitrishaImage from '../assets/dwitrisha.jpeg'; 

const About = () => {
  return (
    <Container className="about-section">
      <Box textAlign="center" py={5}>
        <Typography variant="h3" className="animated-title" gutterBottom>
          About Flywise
        </Typography>

        <Typography variant="body1" paragraph>
          At <strong>Flywise</strong>, we are revolutionizing the way travelers book flights by leveraging
          <strong> AI-powered price monitoring and predictive analysis.</strong>
        </Typography>

        <Typography variant="body1" paragraph>
          As avid travelers, we have experienced firsthand how <strong>flight prices fluctuate constantly,</strong>
          making it difficult to know the right time to book. Since airfare is often one of the <strong>biggest travel
          expenses,</strong> we saw an opportunity to help travelers <strong>save money by booking smarter.</strong>
        </Typography>

        <Typography variant="h4" className="animated-subtitle" gutterBottom>
          Our Mission
        </Typography>

        <Typography variant="body1" paragraph>
          Our goal is simple: <strong>to take the guesswork out of flight booking.</strong> Flywise uses
          <strong> real-time data, historical trends, and AI-driven insights</strong> to analyze price fluctuations and
          provide travelers with clear recommendations on whether to <strong>book now or wait.</strong>
        </Typography>

        <Typography variant="h4" className="animated-subtitle" gutterBottom>
          How Flywise Works
        </Typography>

        <ul className="how-it-works">
          <li><strong>Fetches real-time flight prices</strong> from multiple vendors.</li>
          <li><strong>Analyzes past trends</strong> to detect pricing patterns.</li>
          <li><strong>Uses AI predictions</strong> to suggest the best time to book.</li>
          <li><strong>Helps travelers make cost-effective decisions</strong> and avoid overpaying.</li>
        </ul>

        <Typography variant="body1" paragraph>
          Unlike traditional flight aggregators that only show current prices, <strong>Flywise empowers travelers with insights,</strong>
          ensuring they get the <strong>best deal possible</strong> for their trips.
        </Typography>

        <Typography variant="h4" className="animated-subtitle" gutterBottom>
          Meet the Founders
        </Typography>

        <Box className="founders-container">
          <Box className="founder-card">
            <Avatar src={AnanyaImage} alt="Ananya" className="founder-avatar" />
            <Typography className="founder-name"><strong>Ananya</strong></Typography>
            <Typography className="founder-role">Software Developer at IBM</Typography>
            <Typography className="founder-desc">
              Interned at <strong>Microsoft</strong> and <strong>Oracle</strong>, currently working as a **Software Developer at IBM**.
            </Typography>
          </Box>

          <Box className="founder-card">
            <Avatar src={DwitrishaImage} alt="Dwitrisha" className="founder-avatar" />
            <Typography className="founder-name"><strong>Dwitrisha</strong></Typography>
            <Typography className="founder-role">Software Engineer at Oracle</Typography>
            <Typography className="founder-desc">
              Interned at <strong>Microsoft</strong>, currently working as a **Software Engineer at Oracle**.
            </Typography>
          </Box>
        </Box>

        <Typography variant="h5" mt={3} className="slogan">
          Book smarter, save more – that’s Flywise. ✈️
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
