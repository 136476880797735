import React, { useState } from 'react';
import { Box, Typography, Divider, Button, CircularProgress } from '@mui/material';
import './css/FlightCard.css';
import { fetchVendorPrices } from '../amadeusService'; // Import your function to fetch vendor prices

// Static airport list
const airports = [
  { code: 'DEL', name: 'Delhi' },
  { code: 'BOM', name: 'Mumbai' },
  { code: 'BLR', name: 'Bangalore' },
  { code: 'MAA', name: 'Chennai' },
  { code: 'HYD', name: 'Hyderabad' },
  { code: 'CCU', name: 'Kolkata' },
  { code: 'AMD', name: 'Ahmedabad' },
  { code: 'GOI', name: 'Goa' },
  { code: 'COK', name: 'Kochi' },
  { code: 'PNQ', name: 'Pune' },
  { code: 'JAI', name: 'Jaipur' },
  { code: 'TRV', name: 'Trivandrum' },
  { code: 'JFK', name: 'New York' },
  { code: 'LHR', name: 'London' },
  { code: 'DXB', name: 'Dubai' },
  { code: 'SIN', name: 'Singapore' },
  { code: 'HND', name: 'Tokyo' },
  { code: 'CDG', name: 'Paris' },
  { code: 'LAX', name: 'Los Angeles' },
];

// Helper function to get airport name from the code
const getAirportName = (code) => {
  const airport = airports.find((airport) => airport.code === code);
  return airport ? airport.name : code;
};

const FlightCard = ({ flight }) => {
  const segments = flight.itineraries[0].segments;
  const carrierCode = segments[0].carrierCode;
  const departure = segments[0].departure;
  const arrival = segments[segments.length - 1].arrival;
  const flightNumber = segments[0].number;
  const duration = flight.itineraries[0].duration;
  const numberOfStops = segments.length - 1;

  const [loadingDetails, setLoadingDetails] = useState(false); // Track loading state
  const [vendorPrices, setVendorPrices] = useState(null); // Store vendor prices

  // Airline logo URL
  const airlineLogoUrl = `https://content.airhex.com/content/logos/airlines_${carrierCode}_350_100_r.png`;

  // Use static data to get full airport names
  const departureAirportName = getAirportName(departure.iataCode);
  const arrivalAirportName = getAirportName(arrival.iataCode);

  // If there are stops, find the layover info
  const layovers = segments.slice(0, -1).map((segment, index) => {
    const nextSegment = segments[index + 1];
    const layoverTime = Math.abs(new Date(nextSegment.departure.at) - new Date(segment.arrival.at)) / (1000 * 60); // minutes
    const layoverAirport = getAirportName(segment.arrival.iataCode);
    return { layoverAirport, layoverTime };
  });

  // Fetch vendor prices when "View Details" is clicked
  const handleViewDetails = async () => {
    setLoadingDetails(true);
    const prices = await fetchVendorPrices(flight); // Fetch vendor-specific prices
    setVendorPrices(prices);
    setLoadingDetails(false);
  };

  return (
    <Box className="flight-card">
      {/* Flight Info */}
      <Box className="flight-info">
        {/* Departure Info */}
        <Box className="flight-section">
          <Typography className="airport-code">{departure.iataCode}</Typography>
          <Typography className="airport-name">({departureAirportName})</Typography>
          <Typography className="flight-time">
            Depart: {new Date(departure.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </Typography>
        </Box>

        {/* Airline Logo */}
        <Box className="airline-logo-section">
          <img src={airlineLogoUrl} alt={`${carrierCode} logo`} className="airline-logo" />
        </Box>

        {/* Arrival Info */}
        <Box className="flight-section">
          <Typography className="airport-code">{arrival.iataCode}</Typography>
          <Typography className="airport-name">({arrivalAirportName})</Typography>
          <Typography className="flight-time">
            Arrival: {new Date(arrival.at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
          </Typography>
        </Box>
      </Box>

      <Divider />

      {/* Flight Details */}
      <Box className="flight-details">
        <Typography className="details-text">
          Flight No: {flightNumber} | Duration: {duration.replace('PT', '')} | Price: {flight.price.total} {flight.price.currency}
        </Typography>

        {numberOfStops === 0 ? (
          <Typography className="non-stop">Non-stop</Typography>
        ) : (
          <Typography className="layover-info">
            {numberOfStops} stop(s) with layovers at:{' '}
            {layovers.map((layover, index) => (
              <span key={index}>
                {layover.layoverAirport} ({Math.floor(layover.layoverTime / 60)}h {layover.layoverTime % 60}m)
              </span>
            ))}
          </Typography>
        )}

        {/* View Details Button */}
        <Button onClick={handleViewDetails} className="view-details">
          View Details
        </Button>

        {/* Show vendor prices or loading animation */}
        {loadingDetails ? (
          <CircularProgress size={24} />
        ) : vendorPrices ? (
          <Box>
            <Typography>Vendor Prices:</Typography>
            {vendorPrices.data.flightOffers.map((offer, index) => (
              <Typography key={index}>
                Vendor: {offer.validatingAirlineCodes.join(', ')} | Total Price: {offer.price.total} {offer.price.currency}
              </Typography>
            ))}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default FlightCard;
